export default {
    //上传图片列表地址
    uploadImages: [

    ],
    createShareFlag: false,
    shareListFlag: false,
    currentId: 0,
    tableData: [],
    total: 0,
    currentPage: 1,
    shareId: 0,
    statusList: [],
    allSum: 0,
    req: {
        limit: 30,
        page: 1,
        keywords: '',
        status: ''
    },
    exportStatus: 1,
    exportExcelFlag: false,
}